// App.js - WEB
import React, { Component } from "react";
import { View, StyleSheet, Dimensions, TouchableOpacity, Text, ImageBackground, Image, Modal } from "react-native-web";
import { FaTwitter, FaCheckCircle } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Global from "./Global";
import "./App.css";
import { updateDimensions, styles } from "./styles";
import { unregister } from "./registerServiceWorker";
import Header from "./header";
import Youtube from "react-youtube";
import Footer from "./footer";
import CustomerReel from "./CustomerReel";

import { ref, set, update, get } from "firebase/database";
import { database } from "./firebase.js";
import Demo from "./demos";
import FeatureCard from "./card";

import multiChannelImage from "./img/multichannel_2.png";
import trainingImage from "./img/training_2.png";
import repliesForYouImage from "./img/replies_for_you.gif";
import FooterStealth from "./footerStealth.js";
import Playground from "./playground/playground.js";

var numEmailSent = 0;

var mainImageAlign = "center";
var midImageWidth = 400;
var mainText = 50;
var subText = 24;
var blueBgPaddingLeft = 60;
var headerHorizontalPadding = 60;
var confirmNameText = 48;

let email = "";
const visitId = uuidv4();
console.log("visitId", visitId);

class LangDriveHome extends Component {
    constructor(props) {
        super(props);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const visitSource = urlParams.get("src");

        const refSource = urlParams.get("ref") == null ? "general" : urlParams.get("ref");

        let formattedVisitSource = visitSource == null ? refSource : visitSource;
        formattedVisitSource = formattedVisitSource.replaceAll(/\.|#|\$|[|]/g, ""); // replace illegal firebase path characters

        this.state = {
            isHuman: false,
            name: "",
            email: "",
            timestamp: "",
            browser: "",
            location: "",
            duration: "",
            showConfirmation: false,
            showMainView: true,
            source: formattedVisitSource,

            //   showConfirmation: true,
            //   showMainView: false,
        };
        this.nameInput = React.createRef();
        this.emailInput = React.createRef();
        // this.handleNameInput = this.handleNameInput.bind(this);
        // this.handleNameChange = this.handleNameChange.bind(this);

        // Log this visit
        this.logVisit(formattedVisitSource);
    }
    handleNameInput = (event) => {
        event.preventDefault();
        this.setState({
            name: event.target.value,
        });
    };
    handleEmailInput = (event) => {
        event.preventDefault();
        this.setState({
            email: event.target.value,
        });
    };
    updateDimensions() {
        updateDimensions();
    }
    getDeviceDetails() {
        // alert(startDate.toString());
    }
    /**
     * Add event listener
     */
    componentDidMount() {
        this.updateDimensions();
        // this.getDeviceDetails();
        unregister();

        window.addEventListener("resize", this.updateDimensions.bind(this));
        // Let the team know someone visited
        // TODO: Consider adding this back
        // this.alertTeam();
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillMount() {
        updateDimensions();
    }
    callback = () => {
        // alert("Recaptcha loaded")
    };
    sendButtonPressed = () => {
        // Log this click
        // Previously -> "https://chrome.google.com/webstore/detail/addyai-ai-email-assistant/gldadickgmgciakdljkcpbdepehlilfn",
        this.logClickChrome(this.state.source);
        window.open(
            "https://github.com/addy-ai/langdrive",
            "_blank"
        );
    };

    joinButtonPressed = () => {
        window.location.href = "./signup";
    };

    logVisit(source) {
        if (window.location.hostname.includes("localhost")) return;

        const date = Date.now();

        var visitRef = ref(database, `Analytics/SiteVisit/${source}/${visitId}`);
        set(visitRef, {
            date: date,
            id: visitId,
        }).then(() => {
            // Update the count
            var countRef = ref(database, `Analytics/VisitCount/${source}`);
            get(countRef).then((snapshot) => {
                var count = snapshot.val() == null || !snapshot.val().count ? 0 : snapshot.val().count;
                var num = Number(count);

                if (count != null) {
                    let newCount = num + 1;
                    // update count
                    return update(countRef, {
                        count: newCount.toString(),
                    }).then(() => {
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            }).catch((error) => {
                console.error(error);
            })
        }).catch((error) => {
            console.error(error);
        });
    }

    logClickChrome(source) {
        if (window.location.hostname.includes("localhost")) return;

        const date = Date.now();

        var clickRef = ref(database, `Analytics/DownloadClick/${source}/${visitId}`);
        set(clickRef, {
            date: date,
            id: visitId,
        }).then(() => {
            // Update the count
            var countRef = ref(database, `Analytics/DownloadClickCount/${source}`);
            get(countRef).then((snapshot) => {
                var count = snapshot.val() == null || !snapshot.val().count ? 0 : snapshot.val().count;
                var num = Number(count);

                if (count != null) {
                    let newCount = num + 1;
                    // update count
                    return update(countRef, {
                        count: newCount.toString(),
                    }).then(() => {
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            }).catch((error) => {
                console.error(error);
            })
        }).catch((error) => {
            console.error(error);
        });
    }

    removeChars = (string) => {
        return string.replace(/[^a-zA-Z0-9_@-]/g, "");
    };
    sendConfirmation = (email, endpoint) => {
        // hit endpoint with email
        // If it failed try once again.
        numEmailSent += 1;
        const url = new URL("https://api.ratemynft.app/api/sendlandingconfirmationemail");
        const data = {
            email: email,
        };
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(JSON.stringify(response));
                if (!response.metadata || response.metadata.result !== "success") {
                    // Something went wrong TODO: Log this.
                    if (numEmailSent < 3) {
                        // this.sendConfirmation(email);
                    }
                } else {
                    // Success response.
                }
            })
            .catch((error) => {
                // TODO: Log this error
                if (numEmailSent < 3) {
                    this.sendConfirmation(email);
                }
            });
    };
    onAppLogoClick = () => {
        window.location.href = "/";
        // window.open("https://gifttutoring.org/attachments/download/4563/GIFTSym10_Proceedings_Final_052722.pdf", '_blank');
    };
    MainPage() {
        if (this.state.showMainView == true) {
            return (
                <div className="LangDriveBody">
                    <div
                        id="addy-main-header"
                        className="HeaderContainer"
                        style={{
                            height: 81,
                            width: "99%",
                            paddingLeft: styles.basic.headerHorizontalPadding,
                            top: 0,
                            shadowColor: "#000000",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingRight: styles.basic.headerHorizontalPadding,
                            alignItems: "center",
                            backgroundColor: "#000000"
                        }}>
                        <div className="AppLogo" onClick={this.onAppLogoClick}>
                            <p className="AppTitleText" style={{
                                color: "#FFFFFF",
                                fontSize: "25px"
                            }}>LangDrive
                                {/* <mark className="AppTitleMarker2">Drive</mark> */}
                            </p>


                        </div>

                        <div className="HeaderOptionsDiv" style={{
                            display: "flex", flexDirection: "column",
                            position: "absolute",
                            top: 7,
                            right: 6,
                            // paddingRight: 20, 
                            alignItems: "flex-end"
                        }}>



                            <div id="navbarNavDropdown" style={{
                                width: "180px",
                                height: "50px",
                                padding: "10px",
                                // marginTop: styles.basic.navMarginTop,
                                boxShadow: styles.basic.navBoxShadow,
                                // backgroundColor: "rgb(32, 33, 36)",
                                borderRadius: "20px"
                            }}>
                                <ul class="navbar-nav" style={{
                                    width: "fit-content",
                                }}>

                                    {/* <li class="nav-item">
                                    <a id="addy-nav-link" class="nav-link active" href="#">Pricing</a>
                                </li> */}

                                    {/* <li class="nav-item">
                                            <TouchableOpacity style={{
                                                marginRight: "15px"
                                            }} className="SubmitButton" onPress={() => {
                                                window.open(
                                                    "https://calendly.com/michael-addy-ai/addy-ai-demo",
                                                    "_blank"
                                                );
                                            }}>
                                                <p className="SubmitTextHeader" style={{
                                                    backgroundColor: "#8AB4F8",
                                                    color: "black",
                                                    borderRadius: "30px"
                                                }}>Download</p>
                                            </TouchableOpacity>
                                        </li> */}

                                    {/* <li class="nav-item">
                                            <a style={{
                                                color: "#ffffff"
                                            }} id="addy-nav-link" target="_blank" class="nav-link active" href="https://addy.beehiiv.com">Blog</a>
                                        </li> */}


                                    <li class="nav-item">
                                        <a style={{
                                            color: "#ffffff",
                                            position: "absolute",
                                            zIndex: 99999999,
                                            top: 20,
                                            right: 125,
                                            padding: "0px",
                                            width: "fit-content",
                                        }} id="addy-nav-link" target="_blank" class="nav-link active" href="https://docs.langdrive.ai">Docs</a>
                                    </li>
                                    <li class="nav-item">
                                        <a style={{
                                            color: "#ffffff",
                                            position: "absolute",
                                            zIndex: 99999999,
                                            top: 20,
                                            right: 20,
                                            padding: "0px",
                                            width: "fit-content",
                                        }} id="addy-nav-link" target="_blank" class="nav-link active" href="https://github.com/addy-ai/langdrive">GitHub Repo</a>
                                    </li>


                                    {/* <li class="nav-item">
                                            <TouchableOpacity style={{
                                                marginRight: "15px",
                                            }} className="SubmitButton" onPress={() => {
                                                window.open(
                                                    "https://calendly.com/michael-addy-ai/addy-ai-demo",
                                                    "_blank"
                                                );
                                            }}>
                                                <p className="SubmitTextHeader" style={{
                                                    backgroundColor: "#8AB4F7",
                                                    color: "black"
                                                }}>Request a Demo</p>
                                            </TouchableOpacity>
                                        </li> */}

                                </ul>
                            </div>

                            {/* <p onClick={() => this.gotToLocalHref("/pricing")} className="HeaderOptionButton">Pricing</p> */}
                        </div>


                    </div>

                    <div style={{
                        paddingTop: "50px",
                        paddingBottom: "80px"

                        // paddingBottom: "40px",
                        // minHeight: "100vh",
                    }}>
                        {/* First background */}
                        <View style={{ alignContent: "center", justifyContent: "center" }}>
                            <Row style={{ width: "100%", paddingTop: 40, paddingBottom: 35 }}>
                                {/* The first text lives here */}
                                <Col lg="12">
                                    {/* Text View #6D53AF */}
                                    <View
                                        style={{
                                            height: "fit-content",
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 75,
                                            alignItems: "center",
                                            paddingBottom: 25,
                                            width: "100%",
                                        }}>
                                        <div style={{
                                            width: "100%",

                                        }}>
                                            <div className="HorizontalFlex CenterContents" style={{
                                                width: "100%",
                                                // border: "1px solid red"
                                            }}>
                                                {/* <p
                                                    className="OverflowBreak"
                                                    style={{
                                                        fontSize: styles.basic.smallScreen ? styles.basic.mainText : styles.basic.mainText * 1.2,
                                                        fontWeight: "800",
                                                        color: "#FFFFFF",
                                                        fontFamily: "SF-Pro-Display-Bold",
                                                        textAlign: styles.basic.smallScreen ? "left" : "center",
                                                        width: styles.basic.smallScreen ? "90%" : "80%",
                                                        lineHeight: (styles.basic.mainText * 1.3) + "px"
                                                    }}>
                                                    
                                                    Everything you need to<mark style={{
                                                        backgroundColor: "transparent",
                                                        color: "#C0FD72"
                                                    }}>train LLMs on sensitive data</mark>
                                                </p> */}

                                                <p
                                                    className="OverflowBreak"
                                                    style={{
                                                        fontSize: styles.basic.smallScreen ? styles.basic.mainText : styles.basic.mainText * 1.2,
                                                        fontWeight: "800",
                                                        color: "#FFFFFF",
                                                        fontFamily: "SF-Pro-Display-Bold",
                                                        textAlign: styles.basic.smallScreen ? "left" : "center",
                                                        width: styles.basic.smallScreen ? "90%" : "80%",
                                                        lineHeight: (styles.basic.mainText * 1.3) + "px"
                                                    }}><mark style={{
                                                        backgroundColor: "transparent",
                                                        color: "#C0FD72",
                                                        marginLeft: "0px",
                                                        paddingLeft: "0px"
                                                    }}>Train open source LLMs</mark>with just a single API
                                                </p>

                                            </div>
                                            {/* <Text className="OverflowBreak" style={{
                                                fontSize: mainText * 1.2, fontWeight: "800", color: '#FFFFFF',
                                                fontFamily: 'SF-Pro-Display-Bold'
                                            }}>&nbsp;Google Drive</Text> */}

                                            <p></p>
                                            <div className="subTextDiv HorizontalFlex CenterContents">
                                                {/* <Text className="OverflowBreak" style={{
                                                    fontSize: ((subText * 1.2).toString() + "px"), fontWeight: "bold", color: "#151516",
                                                    fontFamily: 'SF-Pro-Rounded-Light'
                                                }}>
                                                    Save time spent responding to customer emails by training AI
                                                </Text>
                                                <Text className="OverflowBreak" style={{
                                                    fontSize: ((subText * 1.2).toString() + "px"), fontWeight: "bold", color: "#151516",
                                                    fontFamily: 'SF-Pro-Rounded-Light'
                                                }}>&nbsp;to do it for your business 24-7⚡️</Text> */}

                                                <p
                                                    className="OverflowBreak"
                                                    style={{
                                                        // fontSize: styles.basic.smallScreen ? (subText * 0.9).toString() + "px" : (subText).toString() + "px",
                                                        fontSize: styles.basic.subText,
                                                        fontWeight: "bold",
                                                        color: "rgba(255, 255, 255, 0.6)",
                                                        // width: "80%",
                                                        fontFamily: "SF-Pro-Rounded-Light",
                                                        textAlign: styles.basic.smallScreen ? "left" : "center",
                                                        width: styles.basic.smallScreen ? "95%" : "60%"
                                                    }}>
                                                    Simply make an API request to our training endpoint specifying you data and model. LangDrive will handle the rest.
                                                    {/* The AI framework to connect your private data and train LLMs. Own your model weights
                                                    and deploy to Hugging Face. */}
                                                    {/* No external servers needed! */}
                                                </p>
                                            </div>

                                            <div className="HorizontalFlex ChecksDiv" style={{
                                                display: "flex",
                                                gap: 10,
                                                flexWrap: "wrap",
                                                paddingLeft: "15px",
                                                paddingRight: "15px",
                                                marginBottom: styles.basic.smallScreen ? "20px" : "0px",
                                                justifyContent: styles.basic.smallScreen ? "flex-start" : "center",
                                                alignItems: styles.basic.smallScreen ? "flex-start" : "center",
                                            }}>
                                                <p className="check-text" style={{ color: "rgba(255, 255, 255, 0.9)" }}>
                                                    <mark className="check-mark">&#10003;</mark> Custom data ingestion
                                                </p>
                                                <p className="check-text" style={{ color: "rgba(255, 255, 255, 0.9)" }}>
                                                    <mark className="check-mark">&#10003;</mark> Training
                                                </p>
                                                <p className="check-text" style={{ color: "rgba(255, 255, 255, 0.9)" }}>
                                                    <mark className="check-mark">&#10003;</mark> Upload to Huggingface
                                                </p>
                                            </div>
                                        </div>
                                    </View>

                                    <div className="HorizontalFlex CenterContents">
                                        <TouchableOpacity onPress={() => this.sendButtonPressed()}>
                                            <p className="SubmitText" style={{
                                                backgroundColor: "#C0FD72",
                                                color: "black",
                                                fontSize: "19px",
                                                // fontWeight: "bold",
                                                fontFamily: "SF-Pro-Display-Bold"
                                            }}><strong>Train Your First LLM →</strong></p>
                                        </TouchableOpacity>
                                        {/*
                    <TouchableOpacity onPress={() => this.joinButtonPressed()}>
                      <p className="SubmitText">Log in</p>
                    </TouchableOpacity> 
                        */}
                                    </div>
                                </Col>
                                {/* Download button lives here but will be hidden on mobile */}
                                {/* <Col lg="6">
                                    <View
                                        style={{
                                            height: "fit-content",
                                            display: "flex",
                                            flexDirection: "column",
                                            marginTop: 75,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "30px",
                                            borderRadius: "20px",
                                            backgroundColor: "rgba(255, 255, 255, 0.1)"
                                        }}>
                                        <div
                                            className="FullParentDimensions VerticalFlex OSImageContainer"
                                            style={{ alignItems: mainImageAlign }}>
                                            <Image
                                                id="MainImagePromo"
                                                style={{ width: styles.basic.demoImageWidth,
                                                    height: styles.basic.demoImageWidth,
                                                }}
                                                source={require("./img/langdrive_infra_2.png")}
                                                resizeMode="cover"
                                            />
                                        </div>
                                    </View>
                                </Col> */}
                            </Row>
                        </View>
                        <div className="RadialGradientHome"></div>
                    </div>

                    {/* <div className="MainDemoContainer">

                        <div
                            className="FullParentDimensions VerticalFlex"
                            style={{ alignItems: mainImageAlign }}>
                            <img className="MainImagePromoElevated"
                                src="https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Fmain_demo_gif.gif?alt=media&token=4ca518c8-549d-4cd5-a18b-561e181e63b8"
                                style={{
                                    width: "80%",
                                    height: "80%",
                                    borderRadius: "20px",
                                    objectFit: "cover",
                                    // border: "1px solid #6B18EF",
                                    // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                    boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px", // with border
                                    // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", // subtle
                                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"

                                }} />


                        </div>
                    </div> */}

                    {/* <div
                        className="AboutContainer"
                        style={{
                            width: "100%",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: 'column',
                            alignItems: "center",
                            paddingBottom: 100,
                        }}>

                        <img src="https://i.imgur.com/URjK8Wa.gif" style={{
                            width: "90%",
                            height: "100px",
                            objectFit: "contain",
                        }} />

                    </div> */}

                    {/* <div className="RadialGradientHow"></div> */}

                    <div
                        className="HowItWorksContainer"
                        style={
                            {
                                // border: "1px solid yellow"
                            }
                        }>
                        <div className="VerticalFlex CenterContents CenterOppositeDirectionToo DownloadBtnDiv">
                            <p style={styles.headerTextDark}><mark
                                style={{
                                    backgroundColor: "transparent",
                                    color: "#FFFFFF",
                                    fontSize: styles.basic.smallScreen ? 30 : styles.headerTextDark.fontSize,
                                }}>Your Data, Your Model, Your Rules!</mark></p>
                            <br />
                            <p style={{
                                fontSize: 20,
                                width: "100%",
                                marginBottom: 0,
                                textAlign: "center",
                                color: "rgba(255, 255, 255, 0.8)"
                            }}>100% free fine-tuning for LLMs</p>
                        </div>



                        {/* How */}
                        <div className="row" style={{
                            paddingTop: "60px",
                            // paddingBottom: "130px",
                            paddingLeft: "30px",
                            paddingRight: "30px",
                        }}>


                            <div className="col-lg-4 d-flex justify-content-center align-items-center">
                                <div className="VerticalFlex feature-card" style={{
                                    background: "linear-gradient(198.18deg, #1F1F1F 9.55%, #000000 125.88%)",
                                    boxShadow: "0px 4px 38px rgba(0, 0, 0, 0.15)",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                }}>
                                    {/* <div className="HorizontalFlex CenterContents">
                                        <img style={{
                                            width: styles.basic.midImageWidth * 0.8,
                                        }} src="https://i.imgur.com/KS5mChD.png"/>

                                    </div> */}

                                    <div style={{
                                        fontSize: styles.headerTextNew.fontSize,
                                        fontWeight: 800,
                                        fontFamily: "SF-Pro-Display-Regular",
                                        color: "#FFFFFF",
                                        marginTop: "40px",
                                    }}>
                                        <p>Connect Your Data</p>
                                    </div>

                                    {/* <div>
                                         <p>{props.subtitle}</p>
                                    </div> */}

                                    <div style={{
                                        height: "110px",
                                    }}>
                                        <p style={{
                                            fontSize: styles.basic.normalText * 0.9,
                                            fontWeight: "normal",
                                            color: "rgba(255, 255, 255, 0.8)",
                                            textAlign: "left",
                                            fontFamily: "SF-Pro-Display-Light",
                                        }}>Easily import data using LangDrive's built-in data connectors, compatible with sources like Google Drive, Google Cloud Firestore, and emails. LangDrive seamlessly formats this data for training.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 d-flex justify-content-center align-items-center">
                                <div className="VerticalFlex feature-card" style={{
                                    background: "linear-gradient(198.18deg, #1F1F1F 9.55%, #000000 125.88%)",
                                    boxShadow: "0px 4px 38px rgba(0, 0, 0, 0.15)",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                }}>
                                    {/* <div className="HorizontalFlex CenterContents">
                                        <img style={{
                                            width: styles.basic.midImageWidth * 0.8,
                                        }} src="https://i.imgur.com/J46O0Pe.png"/>

                                    </div> */}

                                    <div style={{
                                        fontSize: styles.headerTextNew.fontSize,
                                        fontWeight: 800,
                                        fontFamily: "SF-Pro-Display-Regular",
                                        color: "#FFFFFF",
                                        marginTop: "40px",
                                    }}>
                                        <p>Train Opensource LLMs</p>
                                    </div>

                                    {/* <div>
                                         <p>{props.subtitle}</p>
                                    </div> */}

                                    <div style={{
                                        height: "110px",
                                    }}>
                                        <p style={{
                                            fontSize: styles.basic.normalText * 0.9,
                                            fontWeight: "normal",
                                            color: "rgba(255, 255, 255, 0.8)",
                                            textAlign: "left",
                                            fontFamily: "SF-Pro-Display-Light",
                                        }}>Choose any LLM in our supported list, or add a Hugging Face model URL to your YAML config. LangDrive automatically formats the training data, sets up the environment, and runs the training process.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 d-flex justify-content-center align-items-center">
                                <div className="VerticalFlex feature-card" style={{
                                    background: "linear-gradient(198.18deg, #1F1F1F 9.55%, #000000 125.88%)",
                                    boxShadow: "0px 4px 38px rgba(0, 0, 0, 0.15)",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                }}>
                                    {/* <div className="HorizontalFlex CenterContents">
                                        <img style={{
                                            width: styles.basic.midImageWidth * 0.8,
                                        }} src="https://i.imgur.com/9hH7Utf.png"/>

                                    </div> */}

                                    <div style={{
                                        fontSize: styles.headerTextNew.fontSize,
                                        fontWeight: 800,
                                        fontFamily: "SF-Pro-Display-Regular",
                                        color: "#FFFFFF",
                                        marginTop: "40px",
                                    }}>
                                        <p>Own Your Weights</p>
                                    </div>

                                    {/* <div>
                                         <p>{props.subtitle}</p>
                                    </div> */}

                                    <div style={{
                                        height: "110px",
                                    }}>
                                        <p style={{
                                            fontSize: styles.basic.normalText * 0.9,
                                            fontWeight: "normal",
                                            color: "rgba(255, 255, 255, 0.8)",
                                            textAlign: "left",
                                            fontFamily: "SF-Pro-Display-Light",
                                        }}>After training, you can choose to download the trained model weights locally or deploy to Hugging Face.</p>
                                    </div>
                                </div>
                            </div>

                        </div>


                        {/* <div
                            className="AboutContainer"
                            style={{
                                width: "100%",
                                paddingLeft: styles.basic.blueBgPaddingLeft,
                                justifyContent: "center",
                                paddingTop: 10,
                                paddingBottom: 80,
                            }}>
                            <Row style={{ width: "100%", paddingTop: 10 }}>
                                <Col md="6">
                                    <View style={{ paddingTop: 90, paddingBottom: 60, width: "96%" }}>
                                        <Text style={styles.headerTextNew}>Multichannel</Text>
                                        <Text style={styles.sectionSubtitle}>Interact with your customers where they are</Text>
                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "#4E5E70",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>
                                            Whether it's Email, Chat, Social Media or SMS, Addy AI can be seamlessly integrated
                                            across all platforms within minutes to meet your customers right where they are.
                                        </Text>
                                    </View>
                                </Col>

                                <Col md="6">
                                    <div
                                        className="VerticalFlex"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            paddingTop: 60,
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: 30,
                                        }}>
                                        <div className="BorderRadiusImage">
                                            <Image
                                                style={[styles.image, { width: styles.basic.midImageWidth, height: styles.basic.midImageWidth }]}
                                                source={require("./img/multichannel_2.png")}
                                                resizeMode="cover"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div> */}

                        {/* <div
                            className="AboutContainer"
                            style={{
                                width: "100%",
                                paddingLeft: styles.basic.blueBgPaddingLeft,
                                justifyContent: "center",
                                paddingTop: 130,
                                paddingBottom: 90,
                            }}>
                            <Row style={{ width: "100%" }}>
                                <Col lg={{ span: 6, order: 1 }} md={{ span: 6, order: 2 }} xs={{ span: 12, order: 2 }}>
                                    <div
                                        className="FullParentDimensions VerticalFlex OSImageContainer"
                                        style={{ alignItems: mainImageAlign }}>
                                        <div className="BorderRadiusImage">
                                            <Image
                                                style={[styles.image, { width: styles.basic.midImageWidth, height: styles.basic.midImageWidth }]}
                                                source={require("./img/training_2.png")}
                                                resizeMode="cover"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={{ span: 6, order: 2 }} md={{ span: 6, order: 1 }} xs={{ span: 12, order: 1 }}>
                                    <View style={styles.airportIdentifierImageView}>
                                        <Text style={styles.headerTextNew}>Train with your data</Text>
                                        <Text style={styles.sectionSubtitle}>Personalize Addy AI to speak in your brand voice</Text>
                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "#4E5E70",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>
                                            Using your knowledge base, you can train a special AI support agent just for your business,
                                            ensuring human-like responses tailored to your business and specific customers' needs.
                                        </Text>
                                        <br />

                                        <br />
                                        <br />
                                    </View>
                                </Col>
                            </Row>
                        </div> */}

                        {/* <div
                            className="AboutContainer"
                            style={{
                                width: "100%",
                                paddingLeft: styles.basic.blueBgPaddingLeft,
                                justifyContent: "center",
                                paddingBottom: 200,
                            }}>
                            <Row style={{ width: "100%", paddingTop: 100 }}>
                                <Col md="6">
                                    <View style={{ paddingTop: 40, paddingBottom: 60, width: "96%" }}>
                                        <Text style={styles.headerTextNew}>Always On, Always There!</Text>
                                        <Text style={styles.sectionSubtitle}>Provide round-the-clock customer service</Text>
                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "#4E5E70",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>
                                            Turn on the 'Background Response' feature and our smart email AI will take over for you. It will
                                            reply to all your customer emails 24/7, givng you more time to focus on growing your
                                            business.
                                        </Text>
                                    </View>
                                </Col>

                                <Col md="6">
                                    <div
                                        className="VerticalFlex"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            paddingTop: 40,
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}>
                                        <div className="BorderRadiusImage">
                                            <Image
                                                style={[styles.image, { width: styles.basic.midImageWidth, height: styles.basic.midImageWidth }]}
                                                source={require("./img/replies_for_you.gif")}
                                                resizeMode="cover"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div> */}
                    </div>


                    <div id="playground" style={{
                        // border: "1px solid red",
                        paddingTop: "100px",
                    }}>
                        <div className="VerticalFlex CenterContents CenterOppositeDirectionToo DownloadBtnDiv">
                            <p style={styles.headerTextDark}><mark
                                style={{
                                    backgroundColor: "transparent",
                                    color: "#FFFFFF",
                                    fontSize: styles.basic.smallScreen ? 30 : styles.headerTextDark.fontSize,
                                }}>Playground</mark></p>
                            <br />
                            <p style={{
                                fontSize: 20,
                                width: "100%",
                                marginBottom: 0,
                                textAlign: "center",
                                color: "rgba(255, 255, 255, 0.8)"
                            }}>Try out our supported open source models</p>
                        </div>
                        <br />
                        <Playground />
                    </div>






                    <div
                        className="HowItWorksContainer"
                        style={
                            {
                                // border: "1px solid yellow"
                            }
                        }>
                        <div className="VerticalFlex CenterContents CenterOppositeDirectionToo DownloadBtnDiv">
                            <p style={styles.headerTextDark}><mark
                                style={{
                                    backgroundColor: "transparent",
                                    color: "#FFFFFF",
                                    fontSize: styles.basic.smallScreen ? 30 : styles.headerTextDark.fontSize,
                                }}>We also built</mark></p>
                            <br />
                            {/* <p style={{
                                fontSize: 20,
                                width: "100%",
                                marginBottom: 0,
                                textAlign: "center",
                                color: "rgba(255, 255, 255, 0.8)"
                            }}>100% free fine-tuning for LLMs</p> */}
                        </div>



                        {/* How */}
                        <div className="row" style={{
                            paddingTop: "60px",
                            // paddingBottom: "130px",
                            paddingLeft: "30px",
                            paddingRight: "30px",
                        }}>


                            <div className="col-lg-4 d-flex justify-content-center align-items-center" onClick={() => {
                                window.open("https://addy.so", "_blank");
                            }}>
                                <div className="VerticalFlex feature-card" style={{
                                    background: "linear-gradient(198.18deg, #1F1F1F 9.55%, #000000 125.88%)",
                                    boxShadow: "0px 4px 38px rgba(0, 0, 0, 0.15)",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                }}>
                                    {/* <div className="HorizontalFlex CenterContents">
                                        <img style={{
                                            width: styles.basic.midImageWidth * 0.8,
                                        }} src="https://i.imgur.com/KS5mChD.png"/>

                                    </div> */}

                                    <div style={{
                                        fontSize: styles.headerTextNew.fontSize,
                                        fontWeight: 800,
                                        fontFamily: "SF-Pro-Display-Regular",
                                        color: "#FFFFFF",
                                        marginTop: "40px",
                                    }}>
                                        <p>Addy AI</p>
                                    </div>

                                    {/* <div>
                                         <p>{props.subtitle}</p>
                                    </div> */}

                                    <div style={{
                                        height: "110px",
                                    }}>
                                        <p style={{
                                            fontSize: styles.basic.normalText * 0.9,
                                            fontWeight: "normal",
                                            color: "rgba(255, 255, 255, 0.8)",
                                            textAlign: "left",
                                            fontFamily: "SF-Pro-Display-Light",
                                        }}>AI-powered email that lets you train custom AI assistants to help you with your emails. Prioritize your inbox, summarize key details, and write personalized emails in seconds.</p>
                                    </div>
                                </div>
                            </div>

                            



                        </div>

                    </div>




                    {/* <div
                        className="AboutContainer"
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingTop: 20,
                            paddingBottom: 80,
                            // backgroundColor: "#F9FAFF",
                        }}>

                        <p style={styles.headerTextDark}><mark
                            style={{
                                backgroundColor: "transparent",
                                color: "#745DDE",
                            }}>Save time</mark>spent on customer inquiries</p>
                        <br />
                        <div className="row" style={{ marginTop: "60px", padding: 40 }}>



                            <div className="col-lg-6 justify-content-center">

                                <div
                                    className="VerticalFlex OppositeCenter"
                                    style={{
                                        // width: "90%",
                                        borderRadius: "20px",
                                        // backgroundColor: "#EBF2FF",
                                        backgroundColor: "white",
                                        // backgroundColor: "#EBEEFF",
                                        paddingTop: 50,
                                        paddingBottom: 50,
                                        paddingRight: 30,
                                        paddingLeft: 30,
                                        border: "0.5px solid rgba(0, 0, 0, 0.2)"
                                    }}>
                                    <Youtube
                                        opts={{
                                            playerVars: {
                                                autoplay: 0,
                                                loop: 1,
                                            },
                                            width: styles.basic.midImageWidth,
                                            height: styles.basic.midImageWidth,
                                        }}
                                        videoId="l5xExQlTE1U"
                                    />

                                    <br />

                                    <p className="TestimonialDescription">
                                        "Addy AI makes dealing with repeated customer emails a lot easier and helps save time"
                                    </p>
                                    <p className="TestimonialName">Carl</p>
                                    <p className="TestimonialTitle">CEO, Vinciage Consulting</p>
                                </div>

                            </div>

                            <div className="col-lg-6">

                                <div>
                                    <div style={{ height: "300px" }}>
                                        <iframe id="-N_jXYmjHEIXtGp19-4o" src="https://embed-v2.testimonial.to/share/-N_jglbWrXdObHILrny4" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                                    </div>
                                    <div style={{ height: "300px" }}>
                                        <iframe id="-N_jXYmjHEIXtGp19-4o" src="https://embed-v2.testimonial.to/share/-N_jXYmjHEIXtGp19-4o" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                                    </div>
                                </div>
                            </div>

                            <div className="VerticalFlex CenterContentsCompletely" style={{
                                paddingTop: 80,
                                paddingBottom: 10
                            }}>
                                <TouchableOpacity className="SubmitButton" onPress={() => this.sendButtonPressed()}>
                                    <p className="SubmitText">Get Started for Free</p>
                                </TouchableOpacity>
                            </div>

                        </div>

                    </div> */}

                    <FooterStealth backgroundColor="black" textColor="rgba(255, 255, 255, 0.9)" iconColor="rgba(255, 255, 255, 0.8)"

                        showLinkedIn={true} showTwitter={true} showYoutube={false} showCallUs={false}
                        showTalkWithFounders={false} showPrivacyPolicy={false} showTerms={false}
                        showBlog={false} showSalesEmail={true} />

                </div>
            );
        } else {
            return null;
        }
    }

    Showconfirmation() {
        if (this.state.showConfirmation == true) {
            return (
                <Modal style={styles.mainConfirmationView}>
                    <View
                        style={{
                            width: "100%",
                            height: "100%",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 5,
                        }}>
                        <Text
                            style={{
                                fontWeight: "700",
                                fontStyle: "normal",
                                fontSize: confirmNameText,
                                textAlign: "center",
                                wordWrap: "break-word",
                                fontFamily: "SF-Pro-Display-Bold",
                                color: "#2B1C50",
                                marginBottom: 90,
                                marginTop: 50,
                            }}>
                            The Future of Email is Here
                        </Text>
                        <Image style={styles.checkImage} source={require("./img/check_circle.svg")} resizeMode="cover" />
                        <Text
                            style={{
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontSize: confirmNameText,
                                textAlign: "center",
                                wordWrap: "break-word",
                                fontFamily: "SF-Pro-Display-Bold",
                                color: "#2B1C50",
                                marginBottom: 18,
                                marginTop: 30,
                            }}>
                            Thank you!!
                        </Text>
                        <Text style={styles.youSigned}>Your email was successfully submitted.</Text>
                        <Text style={styles.wewill}>We will add you to the waitlist</Text>
                        <View style={{ borderTopColor: "#E8E8E8", borderTopWidth: 1, width: "40%", opacity: 0.7 }}></View>
                        {/* <Text style={styles.ifyou}> If you did not receive an email, click <a onClick={() => this.sendConfirmation(email)} href="" color="blue">resend email</a></Text> */}
                        <div className="AppLogo" onClick={this.onAppLogoClick}>
                            <p className="AppTitleText">Addy AI</p>
                        </div>
                        <br />
                        <br />
                    </View>

                    {/* <Image
                      style={styles.confirmationImage}
                      source={this.state.imageSrc || require('./img/frame2.png')}
                      resizeMode='cover'
                    /> */}
                </Modal>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div style={{ height: "100vh" }}>
                {this.Showconfirmation()}
                {this.MainPage()}
            </div>
        );
    }
}

export default function (props) {
    // const navigation = useNavigation();
    return <LangDriveHome />;
}
