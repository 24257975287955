// index.js - WEB
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Tos from "./tos";
import PrivacyPolicy from "./pp";
import Installed from "./onboarding/installed";
import Updated from "./onboarding/updated";
import Pricing from "./pricing/pricing";
import IntroducingPricing from "./pricing/introducing-pricing";
import PaymentSuccessful from "./pricing/successful-payment";
import UpgradeInstructions from "./pricing/how-to-upgrade";
import UnInstalled from "./onboarding/uninstalled";
import DeleteAccount from "./delete-account/delete-account";
import AcceptBusinessInvite from "./onboarding/acceptBusinessInvite";
import Poll from "./polls/poll";
import AcceptWorkshopInvite from "./workshops/acceptInvite";
import LangDrive from "./langDrive";
import EmailAssistant from "./emailAssistant";
import AboutUs from "./aboutUs";
import InvestorInfo from "./investorInfo";
import TrainingInstructions from "./onboarding/howToTrain";
import Stealth from "./stealth";
import AboutUsStealth from "./aboutUsStealth";

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<LangDrive />} />
      {/* <Route path="/customer-support" element={<LangDrive/>} /> */}
      {/* <Route path="/termsofservice" element={<Tos />} /> */}
      {/* <Route path="/privacypolicy" element={<PrivacyPolicy />} /> */}
      {/* <Route path="/chrome-installed" element={<Installed />} /> */}
      {/* <Route path="/chrome-updated" element={<Updated />} /> */}
      {/* <Route path="/pricing" element={<Pricing />} /> */}
      {/* <Route path="/introducing-pricing" element={<IntroducingPricing />} /> */}
      {/* <Route path="/subscribed" element={<PaymentSuccessful />} /> */}
      {/* <Route path="/how-to-upgrade" element={<UpgradeInstructions />} /> */}
      {/* <Route path="/how-to-train" element={<TrainingInstructions />} /> */}
      {/* <Route path="/chrome-uninstalled" element={<UnInstalled />} /> */}
      {/* <Route path="/delete-account" element={<DeleteAccount />} /> */}
      {/* <Route path="/accept-business-invite" element={<AcceptBusinessInvite />} /> */}
      {/* <Route path="/poll" element={<Poll />} /> */}
      {/* <Route path="/workshop/register" element={<AcceptWorkshopInvite />} /> */}
      {/* <Route path="/products/langdrive" element={<LangDrive />} /> */}
      {/* <Route path="/email" element={<EmailAssistant />} /> */}
      {/* <Route path="/about-us" element={<AboutUs />} /> */}
      {/* <Route path="/investor-info" element={<InvestorInfo />} /> */}
      {/* <Route path="/stealth" element={<Stealth />} /> */}
      {/* <Route path="/about" element={<AboutUsStealth />} /> */}
    </Routes>
  </BrowserRouter>
);
