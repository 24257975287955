// App.js - WEB
import React, { Component } from "react";
import { StyleSheet, Dimensions } from "react-native";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { v4 as uuidv4 } from 'uuid';
import Header from ".././header";
import Footer from "../footer";
import { updateDimensions, styles } from "../styles";
import { ref, set, update, get } from "firebase/database";
import { database } from "../firebase.js";

const visitId = uuidv4();
console.log("visitId", visitId);
//uuid();

class UnInstalled extends Component {
    constructor(props) {
        super(props);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const visitSource = urlParams.get("src");

        let formattedVisitSource = visitSource == null ? "unknown" : visitSource;
        formattedVisitSource = formattedVisitSource.replaceAll(/\.|#|\$|[|]/g, ""); // replace illegal firebase path characters

        this.state = {
            uid: formattedVisitSource,
        };
        // Log uninstall
        this.logUninstall(formattedVisitSource);
    }

    updateDimensions() { }

    componentDidMount() {
        this.updateDimensions();

        window.addEventListener("resize", this.updateDimensions.bind(this));
        // Let the team know someone visited
        // TODO: Consider adding this back
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    logUninstall(source) {
        if (window.location.hostname.includes("localhost")) return;

        const date = Date.now();

        const d = new Date(date);

        const day = d.getDate();
        const month = d.getMonth() + 1; // add 1 because getMonth() returns zero-based values
        const year = d.getFullYear();

        const uninstallDateString = `${month}-${day}-${year}`;

        var visitRef = ref(database, `Analytics/UninstallLog/${source}/${visitId}`);
        
        set(visitRef, {
            ddate: date,
            dateString: uninstallDateString,
            id: visitId,
            uid: source,
        }).then(() => {
            // Update the count
            var countRef = ref(database, `Analytics/UninstallLogCount/${uninstallDateString}`);
            get(countRef).then((snapshot) => {
                var count = snapshot.val() == null || !snapshot.val().count ? 0 : snapshot.val().count;
                var num = Number(count);

                if (count != null) {
                    let newCount = num + 1;
                    // update count
                    return update(countRef, {
                        count: newCount.toString(),
                    }).then(() => {
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            }).catch((error) => {
                console.error(error);
            })
        }).catch((error) => {
            console.error(error);
        });
        
    }

    componentWillMount() { }

    MainPage() {
        return (
            <div className="AboutUsContainer" style={{ height: "100vh" }}>
                <Header />
                {/* How */}
                <div
                    className="row AboutContainer"
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        paddingLeft: 10,
                        paddingRight: 10,
                        justifyContent: "center",
                        paddingTop: 50,
                        marginTop: 50,
                        height: "100%",
                        backgroundColor: "#ebf0f8",
                    }}>
                    <div className="col-md-6">
                        <div style={{
                            // padding:"100% 0 0 0",
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <p style={styles.headerTextWhite3}>Please fill our feedback form</p>
                            <iframe src="https://player.vimeo.com/video/849995796?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{
                                // position:"absolute",
                                // top: "0",
                                // left: "0",
                                width: "400px",
                                height: "400px",
                                marginTop: "20px",
                                marginBottom: "20px",
                                borderRadius: "15px",
                                
                                // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                // padding: "25px",
                                // backgroundColor: "white"
                            }} title="">
                            </iframe>
                            <a style={{
                                color: "#1D344B",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                                paddingLeft: "30px",
                                paddingRight: "30px",
                                border: "1px solid #1D344B",
                                textDecoration: "none",
                                borderRadius: "10px",
                                fontSize: "15px",
                                fontWeight: "bold",
                            }} href="https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn" target="_blank">Reinstall extension</a>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <iframe
                            src="https://docs.google.com/forms/d/e/1FAIpQLSdXxzntAW_5BCnzogfk_3cPl7B2s4zZRARE3j-1k9844D-shg/viewform?embedded=true"
                            width="90%"
                            height="90%"
                            frameborder="0"
                            marginheight="0"
                            marginwidth="0">
                            Loading…
                        </iframe>
                    </div>

                </div>
                {/* <Footer /> */}
            </div>
        );
    }

    render() {
        return <div style={{ height: "100vh" }}>{this.MainPage()}</div>;
    }
}

export default UnInstalled;
