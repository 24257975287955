import { StyleSheet } from "react-native";

export const styles = {
  image: {
    borderRadius: 10,
  },
  basic: {
    header_text_size: 26,
    footerSubHeadingText: 36,
    footerSubHeadingWeight: 800,
    downloadImageWidth: 153,
    downloadImageHeight: 60,
    signUpHeadingText: 48,
    signUpDateText: 24,
    signUpHeadingWeight: 800,
    mainImageAlign: "center",
    centerImageViewWidth: "70%",
    midImageWidth: 400,
    demoImageWidth: 400,
    fovImageWidth: 200,
    midImageHeight: 400,
    midImageTopPadding: 110,
    mainText: 40,
    subText: 24,
    normalText: 18,
    mainTextViewAlign: "center",
    whiteBgHeight: 560,
    blueBgPaddingLeft: 50,
    footerBottomPadding: 90,
    footerHeight: 100,
    betaViewWidth: 486,
    footerTopPadding: 0,
    footerTextAlign: "center",
    airportTextAlign: "left",
    headerHorizontalPadding: 70,
    iconSize: "1.5em",
    headerShadowRadius: 5,
    headerShadowOpacity: 0.7,
    headerZIndex: 210,
    confirmNameText: 48,
    demoHeight: "550px",
    titleText: 30,
    navMarginTop: "none",
    navBoxShadow: "none",
    demoDivWidth: "90%",
    smallScreen: false,
  },
  headerTextNew: {
    fontSize: 30,
    fontWeight: 800,
    fontFamily: "SF-Pro-Display-Bold",
    // color: "#2B1C50",
    color: "#1D344B",
    marginBottom: 0,
  },
  headerTextNewWhite: {
    fontSize: 40,
    fontWeight: 800,
    fontFamily: "SF-Pro-Display-Bold",
    // color: "#2B1C50",
    color: "#FFFFFF",
    marginBottom: 0,
  },
  sectionSubtitle: {
    marginTop: 15,
    marginBottom: 25,
    fontSize: 20,
    // fontWeight: 800,
    fontFamily: "SF-Pro-Display-Regular",
    // color: "#2B1C50",
    color: "black",
  },
  container: {
    backgroundColor: "#1C1B1C",
  },
  mainConfirmationView: {
    position: "absolute",
    zIndex: 600,
    width: "100%",
    height: "100vh",
    top: 0,
    backgroundColor: "#292929",
    borderColor: "transparent",
  },
  nameText: {
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 48,
    textAlign: "center",
    fontFamily: "SF-Pro-Display-Bold",
    color: "#000000",
    marginBottom: 18,
    marginTop: 60,
  },
  youSigned: {
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: 24,
    textAlign: "center",
    fontFamily: "SF-Pro-Display-Regular",
    color: "#FFFFFF",
    opacity: 0.9,
    marginBottom: 24,
  },
  wewill: {
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: 18,
    textAlign: "center",
    fontFamily: "SF-Pro-Display-Regular",
    color: "#FFFFFF",
    opacity: 0.5,
    marginBottom: 24,
  },
  ifyou: {
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: 12,
    fontFamily: "SF-Pro-Display-Regular",
    color: "#888888",
    marginTop: 24,
    textAlign: "center",
    marginBottom: 66,
  },
  confirmationImage: {
    width: "50%",
    height: "100%",
    position: "absolute",
    zIndex: 400,
    right: 0,
  },
  confirmationLogo: {
    width: 72,
    height: 54,
  },
  checkImage: {
    width: 80,
    height: 80,
  },
  whiteBackground: {
    paddingHorizontal: 70,
    backgroundColor: "#E5E5E5",
    height: 360,
    alignContent: "flex-end",
  },
  mainTextView: {
    height: 360,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    // borderColor: 'red',
    // borderWidth: 2,
    paddingBottom: 105,
  },
  downloadButtonView: {
    height: 360,
    position: "absolute",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    // alignItems: 'flex-end',
    justifyContent: "flex-end",
    paddingBottom: 105,
    zIndex: 208,
  },
  whiteBackgroundAirport: {
    width: "100%",
    paddingLeft: 70,
    backgroundColor: "#E5E5E5",
    height: 568,
  },
  columnBlue1: {
    width: "100%",
    height: 617,
  },
  centeredImageView: {
    width: "70%",
    alignItems: "flex-end",
    // justifyContent: 'flex-end',
    position: "absolute",
    zIndex: 207,
    height: 600,
    // borderColor: 'yellow',
    // borderWidth: 2,
    paddingTop: 110,
  },
  appImage: {
    width: 339,
    height: 610,
    // borderColor: 'green',
    // borderWidth: 2,
  },
  appImage2: {
    width: "100%",
    height: 517,
    // borderColor: 'green',
    // borderWidth: 2,
  },
  logoImage: {
    width: 180,
    height: 60,
  },
  downloadImage: {
    width: "100%",
    height: "100%",
    padding: 10,
  },
  birds: {
    width: 82,
    height: 63,
    padding: 10,
  },
  header: {
    backgroundColor: "#E5E5E5",
    height: 81,
    width: "99%",
    position: "fixed",
    zIndex: 205,
    paddingHorizontal: 70,
  },
  headerTextBlue: {
    fontSize: 42,
    fontWeight: 800,
    color: "#283479",
  },
  headerTextWhite: {
    fontSize: 60,
    fontWeight: 800,
    fontFamily: "SF-Pro-Display-Bold",
    //   color: '#FFFFFF',
    color: "#2B1C50",
    marginBottom: 0,
  },
  headerTextBlueSmall: {
    fontSize: 36,
    fontWeight: 800,
    color: "#283479",
    marginBottom: 35,
  },
  airportIdentifierImageView: {
    width: "100%",
    paddingTop: 20,
    paddingRight: 30,
    //   shadowColor: "#000000",
    //   shadowOffset: {
    //     width: 0,
    //     height: 0
    //   },
    //   shadowRadius: 2,
    //   shadowOpacity: 1,
  },
  paragraphTextWhite1: {
    fontSize: 24,
    fontWeight: "normal",
    color: "#FFFFFF",
    marginBottom: 37,
  },
  paragraphTextWhite2: {
    fontSize: 24,
    fontWeight: "normal",
    color: "#FFFFFF",
  },
  paragraphTextGrey: {
    fontSize: 24,
    fontWeight: "normal",
    color: "#535461",
  },
  blueParagraphView: {
    marginTop: 82,
    // marginBottom: 166,
    width: "96%",
  },
  whiteParagraphView: {
    marginTop: 106,
    marginBottom: 166,
    width: "96%",
  },
  centerParagraph: {
    width: "100%",
    // alignItems: 'center',
    // justifyContent: 'center',
    height: 500,
    paddingRight: 35,
    paddingTop: 106,
  },
  centerParagraph2: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    // borderColor: 'red',
    // borderWidth: 2,
    paddingTop: 152,
    paddingBottom: 184,
  },
  betaView: {
    width: 486,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
    // borderColor: 'red',
    // borderWidth: 2,
    // paddingTop: 152,
    //paddingBottom: 184
  },
  footerDownloadView: {
    width: "100%",
    marginTop: 66,
    marginBottom: 121,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
  },
  footerAvailView: {
    width: "100%",
    height: 84,
    justifyContent: "center",
    paddingBottom: 30,
  },
  footerView: {
    paddingBottom: 90,
    width: "90%",
    justifyContent: "center",
    alignItems: "center",
  },
  footerText: {
    fontWeight: 300,
    fontSize: 12,
    fontFamily: "SF-Pro-Display-Regular",
    textAlign: "center",
    color: "gray",
  },
  signupButtonView: {
    width: "100%",
    height: 56,
    backgroundColor: "#2979FF",
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
    // marginTop: 19,
  },
  textInputMainView: {
    width: "100%",
    height: 56,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    paddingHorizontal: 10,
    borderRadius: 6,
    marginBottom: 15,
  },
  textInputInternalView: {
    width: 378,
    justifyContent: "center",
    // alignItems: 'center',
    height: "100%",
  },
  textInputLabel: {
    color: "#011F8E",
    fontSize: 12,
  },
  textInputEmail: {
    width: "100%",
    height: 24,
    fontSize: 16,
    fontFamily: "Roboto",
    color: "black",
  },
  signup: {
    fontSize: 17,
    fontWeight: 600,
    textAlign: "center",
    color: "#FFFFFF",
  },
  headerTextWhite: {
    fontSize: 50,
    fontWeight: 800,
    fontFamily: "SF-Pro-Display-Bold",
    //   color: '#FFFFFF',
    // color: "#2B1C50",
    color: "#1D344B",
    width: "100%",
    marginBottom: 0,
    textAlign: "center",
  },
  headerTextWhite2: {
    fontSize: 40,
    fontWeight: 800,
    fontFamily: "SF-Pro-Display-Bold",
    // color: "#1D344B",
    color: "#2B1C50",
    width: "100%",
    marginBottom: 0,
    textAlign: "center",
  },
  headerTextWhite3: {
    fontSize: 30,
    fontWeight: 800,
    fontFamily: "SF-Pro-Display-Bold",
    color: "#1D344B",
    // color: "#2B1C50",
    width: "fit-content",
    marginBottom: 0,
    textAlign: "center",
  },
  headerTextDark: {
    color: "#1D344B",
    // color: "#000000",
    fontSize: 45,
    fontWeight: 800,
    fontFamily: "SF-Pro-Display-Bold",
    width: "100%",
    marginBottom: 0,
    textAlign: "center",
  },
  headerSubtitle: {
    // color: "#1D344B",
    color: "rgb(108, 102, 132)",
    fontSize: 18,
    // fontWeight: 800,
    fontFamily: "SF-Pro-Display-Regular",
    width: "100%",
    marginBottom: 0,
    textAlign: "center",
  }
};

export function updateDimensions() {
  // alert("Was here");
  if (window.innerWidth <= 768) {
    styles.basic.footerSubHeadingText = 12;
    styles.basic.footerSubHeadingWeight = 500;
    styles.basic.downloadImageWidth = 89;
    styles.basic.downloadImageHeight = 35;
    styles.basic.signUpHeadingText = 48;
    styles.basic.signUpDateText = 18;
    styles.basic.signUpHeadingWeight = 800;
    styles.basic.centerImageViewWidth = "100%";
    styles.basic.midImageWidth = 300;
    styles.basic.demoImageWidth = 300;
    styles.basic.midImageHeight = 378;
    styles.basic.midImageTopPadding = 20;
    styles.basic.mainText = 25;
    styles.basic.subText = 18;
    styles.basic.normalText = 18;
    styles.basic.mainTextViewAlign = "center";
    styles.basic.whiteBgHeight = 400;
    styles.basic.blueBgPaddingLeft = 20;
    styles.basic.footerBottomPadding = 10;
    styles.basic.betaViewWidth = 290; // ch
    styles.basic.footerHeight = 100;
    styles.basic.footerTopPadding = 80;
    styles.basic.footerTextAlign = "left";
    styles.basic.airportTextAlign = "center";
    styles.basic.headerHorizontalPadding = 28;
    styles.basic.iconSize = "2.5em";
    styles.basic.headerShadowRadius = 0;
    styles.basic.headerShadowOpacity = 0;
    styles.basic.confirmNameText = 30;
    styles.basic.headerZIndex = 205;
    styles.basic.demoHeight = "200px";
    styles.headerTextNew.fontSize = 20;
    styles.sectionSubtitle.fontSize = 20;
    styles.basic.navMarginTop = "10px";
    styles.basic.navBoxShadow = "rgba(0, 0, 0, 0.24) 0px 3px 8px";
    styles.basic.demoDivWidth = "100%";
    styles.basic.smallScreen = true;
  } else {
    styles.basic.footerSubHeadingText = 36;
    styles.basic.footerSubHeadingWeight = 800;
    styles.basic.downloadImageWidth = 153;
    styles.basic.downloadImageHeight = 60;
    styles.basic.signUpHeadingText = 48;
    styles.basic.signUpDateText = 24;
    styles.basic.signUpHeadingWeight = 800;
    styles.basic.centerImageViewWidth = "70%";
    styles.basic.midImageWidth = 350;
    styles.basic.demoImageWidth = 400;
    styles.basic.midImageHeight = 400;
    styles.basic.midImageTopPadding = 110;
    styles.basic.mainText = 30;
    styles.basic.subText = 24;
    styles.basic.normalText = 22;
    styles.basic.mainTextViewAlign = "center";
    styles.basic.whiteBgHeight = 560;
    styles.basic.blueBgPaddingLeft = 60;
    styles.basic.footerBottomPadding = 90;
    styles.basic.betaViewWidth = 486;
    styles.basic.footerHeight = 100;
    styles.basic.footerTopPadding = 0;
    styles.basic.footerTextAlign = "center";
    styles.basic.airportTextAlign = "left";
    styles.basic.iconSize = "1.5em";
    styles.basic.headerHorizontalPadding = 70;
    styles.basic.headerShadowRadius = 5;
    styles.basic.headerShadowOpacity = 0.7;
    styles.basic.headerZIndex = 210;
    styles.basic.confirmNameText = 48;
    styles.basic.demoHeight = "550px";
    styles.headerTextNew.fontSize = 30;
    styles.sectionSubtitle.fontSize = 30;
    styles.basic.navMarginTop = "none";
    styles.basic.navBoxShadow = "none";
    styles.basic.demoDivWidth = "90%";
    styles.basic.smallScreen = false;
  }
  if (window.innerWidth <= 425) {
    styles.basic.midImageWidth = 350;
    styles.basic.demoImageWidth = 350;
    styles.basic.mainText = 35;
    styles.basic.tittleText = 40;
    styles.basic.subText = 20;
    styles.basic.mainImageAlign = "center";
    styles.basic.fovImageWidth = 300;
    styles.basic.demoHeight = "200px";
    styles.basic.normalText = 18;
    styles.basic.titleText = 30;
    // midImageHeight = 300;
  } else if (window.innerWidth <= 990) {
    styles.basic.mainImageAlign = "center";
    styles.basic.midImageWidth = 380;
    styles.basic.demoImageWidth = 400;
    styles.basic.fovImageWidth = 300;
    styles.basic.mainText = 35;
    styles.basic.subText = 20;
    styles.basic.normalText = 18;
    styles.basic.demoHeight = "450px";
    styles.basic.titleText = 34;
    // midImageHeight = 300;
  } else if (window.innerWidth <= 1250) {
    // large
    styles.basic.mainImageAlign = "center";
    styles.basic.midImageWidth = 400;
    styles.basic.demoImageWidth = 600;
    styles.basic.mainText = 35;
    styles.basic.fovImageWidth = 250;
    styles.basic.subText = 20;
    styles.basic.demoHeight = "550px";
    styles.basic.normalText = 18;
    styles.basic.titleText = 40;
    // midImageHeight = 300;
  } else {
    // > 1250
    styles.basic.mainImageAlign = "center";
    styles.basic.midImageWidth = 430;
    styles.basic.demoImageWidth = 600;
    styles.basic.fovImageWidth = 300;
    styles.basic.mainText = 50;
    styles.basic.subText = 25;
    styles.basic.demoHeight = "550px";
    styles.basic.normalText = 20;
    styles.basic.titleText = 40;
    // midImageHeight = 300;
  }
  if (window.innerWidth > 1500) {
    styles.basic.mainImageAlign = "flex-start";
    styles.basic.midImageWidth = 600;
    styles.basic.demoImageWidth = 600;
    styles.basic.fovImageWidth = 400;
    styles.basic.mainText = 55;
    styles.basic.normalText = 20;
  }
}
