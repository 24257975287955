// App.js - WEB
import React, { Component } from "react";
import { FaLinkedin, FaTwitter, FaYoutube, } from "react-icons/fa";
import { updateDimensions } from "./styles";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

class FooterStealth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: props.backgroundColor ? props.backgroundColor : undefined,
            textColor: props.textColor ? props.textColor : undefined,
            iconColor: props.iconColor ? props.iconColor : undefined,
        };
    }
    handleNameInput = (event) => {
        event.preventDefault();
        this.setState({
            name: event.target.value,
        });
    };
    updateDimensions() {
        updateDimensions();
    }
    componentDidMount() {
        this.updateDimensions();

        window.addEventListener("resize", this.updateDimensions.bind(this));
        const headerElement = document.getElementById("addy-main-header");
        window.addEventListener("scroll", () => {
            const scrollPosition = document.documentElement.scrollTop;

            if (scrollPosition > 200) {
                headerElement.style.boxShadow = "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px";
            } else {
                headerElement.style.boxShadow = "none";
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillMount() { }

    MainPage() {
        return (
            <div
                className="FooterContainer row"
                style={{
                    width: "100%",
                    height: "fit-content",
                    paddingHorizontal: 40,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: this.state.backgroundColor ? this.state.backgroundColor : "#f7faff",
                }}>
                <div className="col-md-4" style={{paddingBottom: "40px"}}>
                    <br />
                    <p style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial">©2024 Addy AI, Inc.</p>
                    <br />
                    {this.props.showBlog == false ? <></> : <a style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial Footer-link" href="https://addy.beehiiv.com" target="_blank">
                        Blog
                    </a>}

                    {/* <br /> */}

                    {/* <a style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial Footer-link" href="/" target="_blank">
                        
                    </a> */}


                    {/* <a style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial Footer-link" href="/pricing" target="_blank">
                        Pricing
                    </a> */}

                    {/* <br /> */}
                </div>


                <div className="col-md-4" style={{paddingBottom: "40px"}}>
                    
                    {this.props.showSalesEmail == false ? <></> : <a style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial Footer-link" href="mailto:info@addy-ai.com" target="_blank">
                        info@addy-ai.com
                    </a>}
                    <br />
                    {this.props.showCallUs == false ? <></> : <a style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial Footer-link" href="javascript:void(0);">
                        Call Us: +1 (443) 582 9119
                    </a>}

                </div>

                {/* <div className="VerticalFlex">
                <br/>
                <a className="Footer-link" href="/privacypolicy" target="_blank">
                    Privacy Policy
                </a>
                <br/>
            </div> */}

                <div className="col-md-4" style={{
                    paddingLeft: 50, display: "flex", flexDirection: "row",
                    justifyContent: "center", paddingBottom: "40px"
                }}>

                    {this.props.showTwitter == false ? <></> : <a className="SocialsLink" href="https://twitter.com/addyai_" target="_blank">
                        <FaTwitter color={this.state.iconColor ? this.state.iconColor : "0B3558"} size="1.5em" style={{ paddingRight: 5 }} />
                    </a>}

                    {this.props.showLinkedIn == false ? <></> : <a className="SocialsLink" href="https://www.linkedin.com/company/addy-ai/" target="_blank">
                        <FaLinkedin color={this.state.iconColor ? this.state.iconColor : "0B3558"} size="1.5em" style={{ paddingRight: 5 }} />
                    </a>}
                    {this.props.showYoutube == false ? <></> : <a className="SocialsLink" href="https://www.youtube.com/@addyai" target="_blank">
                        <FaYoutube color={this.state.iconColor ? this.state.iconColor : "0B3558"} size="1.5em" style={{ paddingRight: 5 }} />
                    </a>}
                </div>
            </div>
        );
    }

    render() {
        return <div>{this.MainPage()}</div>;
    }
}

export default FooterStealth;
