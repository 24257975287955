import React, { useState, useRef, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import "./playground.css";
import { styles } from '../styles';

const Playground = (props) => {
    const [messageInput, setMessageInput] = useState("");

    const [minPanelHeight, setMinPanelHeight] = useState(0);

    useEffect(() => {
        const element = document.getElementById('playground-left-panel-1');
        if (element)
            setMinPanelHeight(element.offsetHeight);
    }, []);


    const handleMessageInputChange = (event) => {
        setMessageInput(event.target.value); // Sets inputValue to the current value of the input field
        // console.log(event.target.value);
    }

    // const [isPlaying, setIsPlaying] = useState(false);
    // const [showThumbnail, setShowThumbnail] = useState(true);
    // const videoRef = useRef(null);

    // const togglePlayPause = () => {
    //     if (isPlaying) {
    //         videoRef.current.pause();
    //     } else {
    //         videoRef.current.play();
    //         setShowThumbnail(false);
    //     }
    //     setIsPlaying(!isPlaying);
    // };


    function botMessage(data) {
        return (
            <div style={{
                width: "100%"
            }}>
                <p style={{
                    color: "rgb(156, 163, 175)",
                    lineHeight: "20px",
                    fontSize: "13px",
                    paddingLeft: "35px"
                }}>{data.prompt}</p>

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                    gap: "10px",
                    marginBottom: "25px"
                }}>
                    {/* Bot profile photo */}
                    <div style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "12px",
                        backgroundColor: "#384254",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: 'center',
                        alignItems: "center"
                    }}>
                        <img src="https://i.imgur.com/7zBajsN.png" alt="A.I." style={{
                            borderRadius: "12px",
                        }}
                            width="24" height="24" />
                    </div>

                    {/* Bot Message Div */}
                    <div style={{
                        backgroundColor: "#151E2D",
                        maxWidth: "90%",
                        width: "fit-content",
                        padding: "10px",
                        // borderTopRightRadius: "10px",
                        // borderBottomLeftRadius: "10px",
                        // borderBottomRightRadius: "10px",
                        borderRadius: "10px",
                        border: "1px solid rgb(31, 41, 55)",
                        minWidth: "80px"
                    }}>
                        <p className="chat-message" style={{
                            lineHeight: "20px",
                            color: "#D1D5DA",
                            fontSize: "14px"
                        }}>{data.output}</p>
                    </div>
                </div>
            </div>
        )
    }

    async function clearPreviouslyTyped(messageBox) {
        // Find all line breaks and typed id
        const writtenTexts = messageBox.querySelectorAll('[id^=addy-typed-segment-]');
        const linebreaks = messageBox.querySelectorAll(".addy-line-break");

        if (writtenTexts && writtenTexts.length) {
            for (let i = 0; i < writtenTexts.length; i++) {
                const txt = writtenTexts[i];
                txt.remove();
            }
        }
        if (linebreaks && linebreaks.length) {
            for (let i = 0; i < linebreaks.length; i++) {
                const lb = linebreaks[i];
                lb.remove();
            }
        }
    }

    function insertAfter(newNode, existingNode) {
        existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
    }

    function addLineBreak(previousNode) {
        if (previousNode == null) return;
        const linebreak = document.createElement("div")
        linebreak.setAttribute("class", "addy-line-break");
        const br = document.createElement("br");

        linebreak.append(br);
        // Add div to document but in a specific location.
        insertAfter(linebreak, previousNode);
        return linebreak;
    }

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function simulateTyping(element, text, speed, scroll = false, scrollContainer) {
        var i = 0;
        async function typer() {
            if (i < text.length) {
                element.innerHTML += text.charAt(i);
                i++;
                await delay(speed);
                await typer();
                // Auto scroll as typing happens
                if (scroll && scrollContainer) scrollContainer.scrollTop = scrollContainer.scrollHeight;
            }
        }
        await typer();
    }

    async function typeSuggestionInMessageBox(messageBox, text, speed, scroll = false, scrollContainer = null) {
        clearPreviouslyTyped(messageBox); // Clear Message box

        const newLineSegments = text.split(/\r?\n/);
        if (!newLineSegments.length || newLineSegments.length < 1) {
            return;
        }

        let prevNode = null;
        for (let j = 0; j < newLineSegments.length; j++) {
            const segmentText = newLineSegments[j];
            if (segmentText.length < 1 && prevNode !== null) {
                const lineBreak = addLineBreak(prevNode);
                prevNode = lineBreak;
                continue;
            }
            const segmentDiv = document.createElement("div");
            segmentDiv.setAttribute("id", `addy-typed-segment-${j}`);

            // Insert segment div to messagebox
            if (j == 0) {
                // Segment div goes as first child in messagebox
                messageBox.prepend(segmentDiv);
                prevNode = segmentDiv;
                // Type this segment text in segment div
                await simulateTyping(segmentDiv, segmentText, speed, scroll, scrollContainer);
            }
            if (j > 0) {
                if (j !== newLineSegments.length - 1) {
                    // Insert the text div
                    insertAfter(segmentDiv, prevNode);
                    // simulate the typing
                    await simulateTyping(segmentDiv, segmentText, speed, scroll, scrollContainer);
                    prevNode = segmentDiv; // Update prev node
                }
                if (j == newLineSegments.length - 1) {
                    insertAfter(segmentDiv, prevNode);
                    await simulateTyping(segmentDiv, segmentText, speed, scroll, scrollContainer);
                    prevNode = segmentDiv;
                }
            }
        }
    }



    function getChatResponseFromServer(message) {
        const hostName = window.location.host;
        const chatHistory = document.getElementById("chat-history")

        let thinkingElem = botMessage({
            "prompt": message,
            "output": "thinking...",
        });

        thinkingElem = renderToString(thinkingElem);
        const thinkingEl = document.createElement("div");
        thinkingEl.innerHTML = thinkingElem;

        setTimeout(() => {
            chatHistory.append(thinkingEl);
            chatHistory.scrollTop = chatHistory.scrollHeight;
        }, 400);

        let requestData = {
            prompt: message,
            model: "vilsonrodrigues/falcon-7b-instruct-sharded", // We only support one model right now
        }

        let JSONData = JSON.stringify(requestData);
        const responseElement = thinkingEl.querySelector(".chat-message");

        fetch("https://api.langdrive.ai/v1/completions", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSONData
        })
            .then(response => response.json())
            .then(data => {
                // thinkingEl.style.display = "none";
                console.log(data);
                if (data && data.success) {
                    responseElement.innerHTML = "";
                    typeSuggestionInMessageBox(responseElement,
                        data.generated_text, 10, true, chatHistory)
                } else {
                    responseElement.innerHTML = "Sorry something went wrong.";
                }
                chatHistory.scrollTop = chatHistory.scrollHeight;
            }).catch((error) => {
                console.error(error);
                responseElement.innerHTML = "Sorry something went wrong.";
            })
    }

    function onChatClick(prompt, fromInput) {
        const promptExamples = document.getElementById("prompt-examples");
        if (promptExamples) promptExamples.remove();

        if (fromInput) setMessageInput("");
        getChatResponseFromServer(prompt);
    }

    return (
        <div class="text-white playground-section">
            <div class="flex flex-col lg:flex-row justify-center items-start p-10 gap-10">
                {/* Left Panel */}
                <div id="playground-left-panel-1" class="w-full lg:w-3/5 p-6 w-3/5 playground-item-container" style={{
                    height: "fit-content",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}>
                    <div>

                        <div class="flex justify-between items-center mb-6" style={{
                            flexWrap: "wrap"
                        }}>
                            <div class="flex items-center">
                                <span class="text-yellow-400 text-2xl mr-2">⚡</span>
                                <h3 class="font-semibold">LangDrive Chat <span class="text-gray-400 font-normal">v0.0.1</span></h3>
                            </div>
                            <div class="bg-gray-700 text-gray-300 py-1 px-3 rounded-md">
                                <span>Current Model: &nbsp;</span>
                                <select class="bg-gray-700 text-white border-none">
                                    <option>falcon-7b-instruct</option>
                                </select>
                            </div>
                        </div>
                        <p class="mb-6 text-base" style={{
                            width: styles.basic.smallScreen ? "95%" : "50%"
                        }}>Chat with our supported open source models for free via this chat interface.</p>
                    </div>

                    <div id="chat-history" style={{
                        height: "270px"
                        // maxHeight: styles.basic.demoHeight
                    }}>

                        <div id="prompt-examples">
                            <h4 class="font-semibold mb-4">Examples</h4>
                            <div class="grid grid-cols-3 gap-4 mb-6" style={{
                                display: "flex",
                                flexWrap: "wrap"
                            }}>
                                <button class="bg-gray-700 py-2 px-4 rounded-md text-base" onClick={() => onChatClick("Write email from prompt: schedule a meeting at 2PM Friday", false)}>Write email from prompt</button>
                                <button class="bg-gray-700 py-2 px-4 rounded-md text-base" onClick={() => onChatClick("What is deep learning", false)}>What is deep learning</button>
                                <button class="bg-gray-700 py-2 px-4 rounded-md text-base" onClick={() => onChatClick("What are the best places to visit in the USA?", false)}>Plan my trip to the USA</button>
                            </div>
                        </div>

                    </div>

                    <div>


                        {/* <div class="bg-gray-700 p-4 rounded-md">
                            <input type="text" placeholder="Ask anything" class="bg-transparent border-none w-full text-white placeholder-gray-400" />
                        </div> */}

                        <div class="input-container chat-buttons-div bg-gray-700">
                            <input
                                type="text"
                                id="message-input"
                                class="no-border-input"
                                maxlength="1000"
                                placeholder="Ask anything..."
                                autocomplete="off"
                                onChange={handleMessageInputChange}
                                value={messageInput}
                            />
                            <div class="send-button-container">
                                <button id="send-btn" onClick={() => onChatClick(messageInput, true)}>
                                    <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="m13.7612096 12.010246-10.76006891-1.0841632-.00114067-6.85217554c-.00009872-.59300388.48054588-1.07380853 1.07354976-1.07390725.16675147-.00002775.33121768.038783.48036473.11335653l15.85230779 7.92615386c.5303988.2651994.7453854.9101592.480186 1.440558-.1038883.2077764-.2723589.376257-.4801292.4801576l-15.84996765 7.9261566c-.5303831.2652308-1.1753556.0502824-1.4405864-.4801007-.07454342-.1490647-.11336284-.3134359-.11338495-.4801003l-.00090941-6.8526417z" fill="#586170" />
                                    </svg>
                                </button>
                            </div>
                        </div>


                        <div class="text-gray-400 text-sm mt-4">
                            Model: falcon-7b-instruct - Generated content may be inaccurate or false.
                        </div>
                    </div>
                </div>

                {/* Right Panel */}
                <div class="w-full lg:w-2/5 p-6 w-2/5 playground-item-container" style={{
                    height: "fit-content",
                    minHeight: `${minPanelHeight}px`
                }}>
                    <h3 class="font-semibold mb-6">API Reference</h3>
                    <p class="mb-6 text-base">Call our supported models using our API for free.</p>
                    <div class="flex items-center justify-between bg-green-600 text-white py-2 px-4 rounded mb-2 text-lg" style={{
                        overflowWrap: "break-word",
                        wordWrap: "break-word",
                        flexWrap: "wrap",
                    }}>
                        <span>POST&nbsp;</span>
                        <span style={{
                            display: "block",
                            width: "100%",
                            whiteSpace: "normal",
                            overflowWrap: "anywhere", 
                            wordWrap: "break-word",
                        }}>https://api.langdrive.ai/v1/completions</span>
                    </div>
                    <h4 class="font-semibold mb-2">Request Body</h4>
                    <div class="bg-gray-700 p-4 rounded-md mb-4 pb-2 pt-2">
                        <pre class="text-xs text-gray-400 m-1">&#123;</pre>
                        <pre class="text-xs text-gray-400 m-1">&nbsp;&nbsp;&nbsp;&nbsp;"prompt": "Write me a joke",</pre>
                        <pre class="text-xs text-gray-400 m-1">&nbsp;&nbsp;&nbsp;&nbsp;"model": "vilsonrodrigues/falcon-7b-instruct-sharded" &nbsp;</pre>
                        <pre class="text-xs text-gray-400 m-1">&nbsp;&nbsp;&nbsp;&nbsp;"max_tokens": 2048 &nbsp;</pre>
                        <pre class="text-xs text-gray-400 m-1">&#125;</pre>
                    </div>
                    <h4 class="font-semibold mb-2">Response</h4>
                    <div class="bg-gray-700 p-4 rounded-md pb-2 pt-2">
                        <pre class="text-xs text-gray-400 m-1">&#123;</pre>
                        <pre class="text-xs text-gray-400 m-1">&nbsp;&nbsp;&nbsp;&nbsp;"success": "boolean",</pre>
                        <pre class="text-xs text-gray-400 m-1">&nbsp;&nbsp;&nbsp;&nbsp;"generated_text": "string" &nbsp;</pre>
                        <pre class="text-xs text-gray-400 m-1">&#125;</pre>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Playground;
