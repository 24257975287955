// App.js - WEB
import React, { Component } from "react";
import { View, StyleSheet, Dimensions, TouchableOpacity, Text, ImageBackground, Image, Modal } from "react-native-web";
import { InView } from 'react-intersection-observer';
import { FaTwitter, FaCheckCircle, FaRegCheckCircle } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Global from "./Global";
import "./App.css";
import { updateDimensions, styles } from "./styles";
import { unregister } from "./registerServiceWorker";
import Header from "./header";
import Youtube from "react-youtube";
import Footer from "./footer";
import CustomerReel from "./CustomerReel";
import { InlineWidget } from "react-calendly";

import { ref, set, update, get } from "firebase/database";
import { database } from "./firebase.js";

import Demo from "./demos";
import FeatureCard from "./card";

import multiChannelImage from "./img/multichannel_2.png";
import trainingImage from "./img/training_2.png";
import repliesForYouImage from "./img/replies_for_you.gif";
import demoImage from "./img/ai_plus_cloud.png";
import VideoPlayer from "./components/video";
import zeroInbox from "./img/zero_inbox.gif";
import Customers from "./components/customers";
import MultiLingualDemo from "./components/multilingualDemo";
import FooterStealth from "./footerStealth.js";


var composeDemo = "https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Fcompose_demo_simple.mp4?alt=media&token=ea96c05b-9a8e-4bd2-9397-4536f93b431c";
var followUpDemo = "https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Ffollow_up_demo_simple.mp4?alt=media&token=cb5ca2e6-2558-4a8f-8b8f-63a80a6e2d2e";
var trainingDemo = "https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Ftraining_demo_simple.mp4?alt=media&token=2d5eea3c-4e5b-4577-bb68-d19b6a93f329";
var replyDemo = "https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Freply_demo_simple.mp4?alt=media&token=be11ab20-39ae-497e-9d32-f65fc450b71b";



var numEmailSent = 0;

var mainImageAlign = "center";
var midImageWidth = 400;
var mainText = 50;
var subText = 24;
var blueBgPaddingLeft = 60;
var headerHorizontalPadding = 60;
var confirmNameText = 48;

let email = "";
const visitId = uuidv4();

console.log("visitId", visitId);


const videoOptions = {
    playerVars: {
        autoplay: 1,
        mute: 1,
        loop: 1,
        playlist: "3w_uWqq1xqk"
    },
};

class Stealth extends Component {
    constructor(props) {
        super(props);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const visitSource = urlParams.get("src");

        const refSource = urlParams.get("ref") == null ? "general" : urlParams.get("ref");

        let formattedVisitSource = visitSource == null ? refSource : visitSource;
        formattedVisitSource = formattedVisitSource.replaceAll(/\.|#|\$|[|]/g, ""); // replace illegal firebase path characters

        this.state = {
            isHuman: false,
            name: "",
            email: "",
            timestamp: "",
            browser: "",
            location: "",
            duration: "",
            showConfirmation: false,
            showMainView: true,
            source: formattedVisitSource,

            //   showConfirmation: true,
            //   showMainView: false,
        };
        this.nameInput = React.createRef();
        this.emailInput = React.createRef();
        this.playerRef = React.createRef();
        // this.handleNameInput = this.handleNameInput.bind(this);
        // this.handleNameChange = this.handleNameChange.bind(this);

        // Log this visit
        this.logVisit(formattedVisitSource);
    }

    handleVideo(state) {
        if (state.inView) {
            if (this.playerRef && this.playerRef.current) this.playerRef.current.internalPlayer.playVideo();
        } else {
            if (this.playerRef && this.playerRef.current) this.playerRef.current.internalPlayer.pauseVideo();
        }
    }


    handleNameInput = (event) => {
        event.preventDefault();
        this.setState({
            name: event.target.value,
        });
    };
    handleEmailInput = (event) => {
        event.preventDefault();
        this.setState({
            email: event.target.value,
        });
    };
    updateDimensions() {
        updateDimensions();
    }
    getDeviceDetails() {
        // alert(startDate.toString());
    }
    /**
     * Add event listener
     */
    componentDidMount() {
        this.updateDimensions();
        // this.getDeviceDetails();
        unregister();

        window.addEventListener("resize", this.updateDimensions.bind(this));
        // Let the team know someone visited
        // TODO: Consider adding this back
        // this.alertTeam();

        document.body.addEventListener("scroll", () => {
            const headerElement = document.getElementById("addy-main-header");
            const scrollPosition = document.body.scrollTop;

            if (scrollPosition > 500) {
                if (headerElement) {
                    headerElement.style.boxShadow = "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px";
                    // headerElement.style.backgroundColor = "#FFFFFF";
                }

            } else {
                if (headerElement) {
                    headerElement.style.boxShadow = "none";
                    // headerElement.style.backgroundColor = "#FFFFFF";
                }

            }
        });
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillMount() {
        updateDimensions();
    }
    callback = () => {
        // alert("Recaptcha loaded")
    };
    sendButtonPressed = () => {
        // Log this click
        // Previously -> "https://chrome.google.com/webstore/detail/addyai-ai-email-assistant/gldadickgmgciakdljkcpbdepehlilfn",
        this.logClickChrome(this.state.source);
        window.open(
            "https://forms.gle/TNapGKBDjP3X89dE8",
            "_blank"
        );
    };

    joinButtonPressed = () => {
        window.location.href = "./signup";
    };

    logVisit(source) {
        if (window.location.hostname.includes("localhost")) return;

        const date = Date.now();

        var visitRef = ref(database, `Analytics/SiteVisit/${source}/${visitId}`);
        set(visitRef, {
            date: date,
            id: visitId,
        }).then(() => {
            // Update the count
            var countRef = ref(database, `Analytics/VisitCount/${source}`);
            get(countRef).then((snapshot) => {
                var count = snapshot.val() == null || !snapshot.val().count ? 0 : snapshot.val().count;
                var num = Number(count);

                if (count != null) {
                    let newCount = num + 1;
                    // update count
                    return update(countRef, {
                        count: newCount.toString(),
                    }).then(() => {
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            }).catch((error) => {
                console.error(error);
            })
        }).catch((error) => {
            console.error(error);
        });
    }

    logClickChrome(source) {
        if (window.location.hostname.includes("localhost")) return;

        const date = Date.now();

        var clickRef = ref(database, `Analytics/DownloadClick/${source}/${visitId}`);
        set(clickRef, {
            date: date,
            id: visitId,
        }).then(() => {
            // Update the count
            var countRef = ref(database, `Analytics/DownloadClickCount/${source}`);
            get(countRef).then((snapshot) => {
                var count = snapshot.val() == null || !snapshot.val().count ? 0 : snapshot.val().count;
                var num = Number(count);

                if (count != null) {
                    let newCount = num + 1;
                    // update count
                    return update(countRef, {
                        count: newCount.toString(),
                    }).then(() => {
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            }).catch((error) => {
                console.error(error);
            })
        }).catch((error) => {
            console.error(error);
        });
    }

    removeChars = (string) => {
        return string.replace(/[^a-zA-Z0-9_@-]/g, "");
    };
    sendConfirmation = (email, endpoint) => {
        // hit endpoint with email
        // If it failed try once again.
        numEmailSent += 1;
        const url = new URL("https://api.ratemynft.app/api/sendlandingconfirmationemail");
        const data = {
            email: email,
        };
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(JSON.stringify(response));
                if (!response.metadata || response.metadata.result !== "success") {
                    // Something went wrong TODO: Log this.
                    if (numEmailSent < 3) {
                        // this.sendConfirmation(email);
                    }
                } else {
                    // Success response.
                }
            })
            .catch((error) => {
                // TODO: Log this error
                if (numEmailSent < 3) {
                    this.sendConfirmation(email);
                }
            });
    };
    onAppLogoClick = () => {
        window.location.href = "/";
        // window.open("https://gifttutoring.org/attachments/download/4563/GIFTSym10_Proceedings_Final_052722.pdf", '_blank');
    };
    MainPage() {
        if (this.state.showMainView == true) {
            return (
                <div className="AboutUsContainer" style={{
                    backgroundColor: "rgb(32, 33, 36)"
                }}>
                    {/* <Header /> */}

                    <div
                        id="addy-main-header"
                        className="HeaderContainer"
                        style={{
                            height: 81,
                            width: "100%",
                            paddingLeft: styles.basic.headerHorizontalPadding,
                            top: 0,
                            shadowColor: "#000000",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingRight: styles.basic.headerHorizontalPadding,
                            alignItems: "center",
                            backgroundColor: "rgb(32, 33, 36)"

                        }}>
                        <div className="AppLogo" onClick={this.onAppLogoClick}>
                            <Image
                                style={{ width: 26, height: 26, marginRight: 12 }}
                                source={require("./img/logo.png")}
                                resizeMode="contain"
                            />
                            <p className="AppTitleText" style={{
                                color: "white",
                            }}>Addy AI</p>
                            {/* <mark style={{
                                backgroundColor: "transparent",
                                color: "rgba(255, 255, 255, 0.7)",
                                fontFamily: "SF-Pro-Display-Light"
                            }}>&nbsp;| Email</mark> */}


                        </div>

                        <div className="HeaderOptionsDiv" style={{
                            display: "flex", flexDirection: "column",
                            position: "absolute",
                            top: 7,
                            right: 6,
                            // paddingRight: 20,  
                            alignItems: "flex-end"
                        }}>

                            <nav class="navbar navbar-expand-lg navbar-light">

                                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon navbar-toggler-icon-addy-dark"></span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarNavDropdown" style={{
                                    // width: "180px",
                                    padding: "10px",
                                    marginTop: styles.basic.navMarginTop,
                                    boxShadow: styles.basic.navBoxShadow,
                                    backgroundColor: "rgb(32, 33, 36)",
                                    borderRadius: "20px"
                                }}>
                                    <ul class="navbar-nav" style={{
                                        width: "fit-content",
                                    }}>

                                        {/* <li class="nav-item">
                                    <a id="addy-nav-link" class="nav-link active" href="#">Pricing</a>
                                </li> */}

                                        {/* <li class="nav-item">
                                            <TouchableOpacity style={{
                                                marginRight: "15px"
                                            }} className="SubmitButton" onPress={() => {
                                                window.open(
                                                    "https://calendly.com/michael-addy-ai/addy-ai-demo",
                                                    "_blank"
                                                );
                                            }}>
                                                <p className="SubmitTextHeader" style={{
                                                    backgroundColor: "#8AB4F8",
                                                    color: "black",
                                                    borderRadius: "30px"
                                                }}>Download</p>
                                            </TouchableOpacity>
                                        </li> */}

                                        

                                        {/* <li class="nav-item">
                                            <a style={{
                                                color: "#ffffff"
                                            }} id="addy-nav-link" target="_blank" class="nav-link active" href="https://addy.beehiiv.com">Blog</a>
                                        </li> */}

                                        <li class="nav-item dropdown">
                                            <a style={{
                                                color: "#ffffff"
                                            }} class="nav-link dropdown-toggle active" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Products
                                            </a>
                                            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                <a id="addy-nav-link" class="dropdown-item" href="http://langdrive.ai">LangDrive</a>
                                            </div>
                                        </li>

                                        <li class="nav-item">
                                            <a style={{
                                                color: "#ffffff"
                                            }} id="addy-nav-link" target="_blank" class="nav-link active" href="/about">About Us</a>
                                        </li>


                                        {/* <li class="nav-item">
                                            <TouchableOpacity style={{
                                                marginRight: "15px",
                                            }} className="SubmitButton" onPress={() => {
                                                window.open(
                                                    "https://calendly.com/michael-addy-ai/addy-ai-demo",
                                                    "_blank"
                                                );
                                            }}>
                                                <p className="SubmitTextHeader" style={{
                                                    backgroundColor: "#8AB4F7",
                                                    color: "black"
                                                }}>Request a Demo</p>
                                            </TouchableOpacity>
                                        </li> */}

                                    </ul>
                                </div>
                            </nav>

                            {/* <p onClick={() => this.gotToLocalHref("/pricing")} className="HeaderOptionButton">Pricing</p> */}
                        </div>
                    </div>

                    <div className="PrimarySectionContainer" style={{
                        backgroundColor: "rgb(32, 33, 36)",
                        paddingBottom: "30px",
                        minHeight: "100vh"
                    }}>


                        {/* First background */}
                        <View style={{ paddingTop: 40, paddingLeft: styles.basic.blueBgPaddingLeft, alignContent: "center", justifyContent: "center" }}>

                            {/* <div style={{
                                width: "100%",
                                height: "50px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>

                                <div className="site-announcement" style={{
                                    fontSize: styles.basic.smallScreen ? "12px" : "14px"
                                }}>
                                   Addy AI is now in stealth
                                </div>

                            </div> */}

                            <Row style={{ width: "100%", paddingBottom: 0 }}>
                                {/* The first text lives here */}
                                <Col lg="6">
                                    {/* Text View #6D53AF */}
                                    <View
                                        style={{
                                            height: "fit-content",
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 40,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            paddingBottom: 25,
                                        }}>
                                        <div style={{
                                            width: "100%",
                                            display: "flex", flexDirection: "column",
                                            justifyContent: "center",
                                        }}>

                                            <div style={{
                                                width: "97%", display: "flex",
                                                flexDirection: "column",
                                                // alignItems: "center",
                                                justifyContent: "center"
                                            }}>


                                                <Text
                                                    className="OverflowBreak"
                                                    style={{
                                                        fontSize: styles.basic.mainText,
                                                        fontWeight: "800",
                                                        color: "#FFFFFF",
                                                        textAlign: "left",
                                                        fontFamily: "SF-Pro-Display-Bold",
                                                    }}>
                                                    At the intersection of AI and Cloud computing
                                                    {/* <mark
                                                        style={{
                                                            backgroundColor: "transparent",
                                                            color: "#745DDE",
                                                        }}>10x faster</mark> */}
                                                    {/* with AI */}
                                                </Text>
                                            </div>
                                            {/* <Text className="OverflowBreak" style={{
                                                fontSize: styles.basic.mainText, fontWeight: "800", color: '#000000',
                                                fontFamily: 'SF-Pro-Display-Bold'
                                            }}>&nbsp;not days</Text> */}


                                            <div className="subTextDiv">
                                                {/* <Text className="OverflowBreak" style={{
                                                    fontSize: ((subText * 1.2).toString() + "px"), fontWeight: "bold", color: "#151516",
                                                    fontFamily: 'SF-Pro-Rounded-Light'
                                                }}>
                                                    Save time spent responding to customer emails by training AI
                                                </Text>
                                                <Text className="OverflowBreak" style={{
                                                    fontSize: ((subText * 1.2).toString() + "px"), fontWeight: "bold", color: "#151516",
                                                    fontFamily: 'SF-Pro-Rounded-Light'
                                                }}>&nbsp;to do it for your business 24-7⚡️</Text> */}

                                                <Text
                                                    className="OverflowBreak"
                                                    style={{
                                                        fontSize: (styles.basic.subText).toString() + "px",
                                                        fontWeight: "bold",
                                                        color: "#e9eaee",
                                                        fontFamily: "SF-Pro-Rounded-Light",
                                                    }}>
                                                    We are a stealth startup based in San Francisco.
                                                </Text>
                                                {/* <Text
                                                    className="OverflowBreak"
                                                    style={{
                                                        fontSize: (styles.basic.subText).toString() + "px",
                                                        fontWeight: "bold",
                                                        color: "#151516",
                                                        fontFamily: "SF-Pro-Rounded-Light",
                                                    }}>
                                                    &nbsp;customer inquiries 24-7⚡️
                                                </Text> */}
                                            </div>


                                        </div>
                                    </View>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "40px"
                                    }}>
                                        <TouchableOpacity style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            backgroundColor: "#8ab4f8",
                                            padding: "15px",
                                            borderRadius: "40px",
                                            gap: "15px"
                                        }} onPress={() => this.sendButtonPressed()}>
                                            <p className="SubmitText" style={{
                                                backgroundColor: "#8ab4f8", color: "black",
                                                paddingLeft: "0px", paddingTop: "0px", paddingBottom: "0px", fontSize: "22px",
                                                paddingRight: "0px"
                                            }}>Stay updated</p>
                                        </TouchableOpacity>
                   
                                    </div>


                                </Col>
                                {/* Download button lives here but will be hidden on mobile */}
                                <Col lg="6">
                                    <View
                                        style={{
                                            height: "fit-content",
                                            display: "flex",
                                            flexDirection: "column",
                                            // marginTop: 40,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "45px",
                                            paddingBottom: "0px",
                                            paddingTop: "20px",
                                            borderRadius: "30px",
                                            minWidth: "100%",

                                        }}>
                                        <div
                                            className="VerticalFlex"
                                            style={{ alignItems: mainImageAlign, }}>
                                            <img className="MainImagePromoElevated"
                                                src={demoImage}
                                                style={{
                                                    boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                                                    width: (styles.basic.midImageWidth).toString() + "px",
                                                    height: (styles.basic.midImageWidth - 80).toString() + "px",
                                                    borderRadius: "20px",
                                                    objectFit: "contain",
                                                }} />
                                        </div>


                                    </View>

                                </Col>
                            </Row>

                            <div style={{
                                display: "flex",
                                gap: 40,
                                flexWrap: "wrap",
                                paddingTop: "10px",
                                paddingBottom: "60px"
                                // position: "absolute",
                                // bottom: "-100px"

                            }}>
                                <div style={{
                                    cursor: "pointer",
                                }} onClick={() => window.open("https://github.com/addy-ai/langdrive", "_blank")}>
                                    <div className="skeleton-box" style={{
                                        position: "absolute",
                                        marginTop: "20px",
                                        backgroundColor: "#5850EB",
                                        marginLeft: `${styles.basic.midImageWidth - 80}px`
                                    }}>
                                        <p style={{ color: "#FFFFFF" }} className="highlighting-text">&#129321; New</p>
                                    </div>
                                    <img src="https://i.imgur.com/fyQK7Yt.png" style={{
                                        borderRadius: "25px"
                                    }}
                                        width={`${styles.basic.midImageWidth}px`} height="auto" />

                                </div>

                                <div style={{
                                    cursor: "pointer",
                                }} onClick={() => window.open("https://github.com/addy-ai/langdrive", "_blank")}>
                                    <img src="https://i.imgur.com/d4tugAq.png" style={{
                                        borderRadius: "25px"
                                    }}
                                        width={`${styles.basic.midImageWidth}px`} height="auto" />
                                </div>
                            </div>


                        </View>


                    </div>



                    {/* <div class="yt-video-container">
                        <iframe src="https://www.youtube.com/embed/3w_uWqq1xqk" frameborder="0" allowfullscreen></iframe>
                    </div> */}


                    <div className="AboutContainer container-fluid"
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            // alignItems: "center",
                            // justifyContent: "center",
                            paddingBottom: 80,
                            backgroundColor: "#202125",
                            backgroundColor: "rgba(255, 255, 255, 01)",
                            // backgroundColor: "#3c4043",

                            paddingTop: "70px"
                        }}>

                        <p style={{
                            ...styles.headerTextDark,
                            fontSize: styles.basic.titleText - 9,
                            fontFamily: "SF-Pro-Display-Regular",
                            color: "rgba(0, 0, 0, 0.8)",
                            fontWeight: "normal",
                            marginBottom: "15px"
                        }}>
                            Proudly backed by</p>

                        <div className="VerticalFlex CenterContentsCompletely" style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            width: "100%",
                            gap: "20px",
                        }}>

                            <div style={{
                                display: "flex",
                                gap: 30,
                                flexWrap: "wrap"
                            }}>
                                <img src="https://i.imgur.com/v33lYtV.png"
                                    width="360px" height="auto" />

                                <img src="https://i.imgur.com/AR2u6gb.png"
                                    width="360px" height="auto" />
                            </div>



                            <p style={{
                                color: "rgba(0, 0, 0, 0.7)",
                                fontFamily: "SF-Pro-Display-Light",
                                fontSize: "22px",
                                width: "360px",
                                textAlign: "left"

                            }}>
                                And notable Angels - Merline Saintil
                            </p>

                        </div>

                    </div>

                    <FooterStealth backgroundColor="rgb(32, 33, 36)" textColor="#FFFFFF" iconColor="#FFFFFF"

                        showLinkedIn={true} showTwitter={true} showYoutube={false} showCallUs={false}
                        showTalkWithFounders={false} showPrivacyPolicy={false} showTerms={false}
                        showBlog={false} showSalesEmail={true} />
                </div>
            );
        } else {
            return null;
        }
    }

    Showconfirmation() {
        if (this.state.showConfirmation == true) {
            return (
                <Modal style={styles.mainConfirmationView}>
                    <View
                        style={{
                            width: "100%",
                            height: "100%",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 5,
                        }}>
                        <Text
                            style={{
                                fontWeight: "700",
                                fontStyle: "normal",
                                fontSize: confirmNameText,
                                textAlign: "center",
                                wordWrap: "break-word",
                                fontFamily: "SF-Pro-Display-Bold",
                                color: "#2B1C50",
                                marginBottom: 90,
                                marginTop: 50,
                            }}>
                            The Future of Email is Here
                        </Text>
                        <Image style={styles.checkImage} source={require("./img/check_circle.svg")} resizeMode="cover" />
                        <Text
                            style={{
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontSize: confirmNameText,
                                textAlign: "center",
                                wordWrap: "break-word",
                                fontFamily: "SF-Pro-Display-Bold",
                                color: "#2B1C50",
                                marginBottom: 18,
                                marginTop: 30,
                            }}>
                            Thank you!!
                        </Text>
                        <Text style={styles.youSigned}>Your email was successfully submitted.</Text>
                        <Text style={styles.wewill}>We will add you to the waitlist</Text>
                        <View style={{ borderTopColor: "#E8E8E8", borderTopWidth: 1, width: "40%", opacity: 0.7 }}></View>
                        {/* <Text style={styles.ifyou}> If you did not receive an email, click <a onClick={() => this.sendConfirmation(email)} href="" color="blue">resend email</a></Text> */}
                        <div className="AppLogo" onClick={this.onAppLogoClick}>
                            <p className="AppTitleText">Addy AI</p>
                        </div>
                        <br />
                        <br />
                    </View>

                    {/* <Image
                      style={styles.confirmationImage}
                      source={this.state.imageSrc || require('./img/frame2.png')}
                      resizeMode='cover'
                    /> */}
                </Modal>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div style={{ height: "100vh" }}>
                {this.Showconfirmation()}
                {this.MainPage()}
            </div>
        );
    }
}

export default function (props) {
    // const navigation = useNavigation();
    return <Stealth />;
}
