// App.js - WEB
import React, { Component } from "react";
import { StyleSheet, Dimensions, Image, TouchableOpacity, View } from "react-native";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../header";
import Footer from "../footer";
import { updateDimensions, styles } from "../styles";
import "../pricing/toggle.css";
// import home_backround from './img/home_backround.svg';
var midImageWidth = 400;
var blueBgPaddingLeft = 70;

class TrainingInstructions extends Component {
    updateDimensions() {
        updateDimensions();
        this.setState({});
    }

    componentDidMount() {
        this.updateDimensions();

        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillMount() { }

    MainPage() {
        return (
            <div
                div
                className="AboutUsContainer"
                style={{
                    width: "100%",
                    paddingLeft: blueBgPaddingLeft / 2,
                    paddingRight: blueBgPaddingLeft / 2,
                    justifyContent: "center",
                    marginBottom: 100,
                    paddingTop: 150,
                }}>
                <iframe
                    src="https://4321jq50o4qe17.embednotionpage.com/How-to-Train-Addy-AI-e57f93eb86064da99b32005874236e4a"
                    style={{
                        width: "100%",
                        height: "100vh",
                    }}
                ></iframe>

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "center",
                }}>
                    <a href="mailto:michael@addy-ai.com?subject=Addy AI Business Plan Inquiry" target="_blank" className="SubmitText">Contact Sales</a>
                </div>

            </div>
        );
    }

    render() {
        return (
            <div style={{ height: "100vh" }}>
                <Header />

                {this.MainPage()}

                <Footer backgroundColor="#EFF0FF" textColor="#3D2E7C" iconColor="#3D2E7C" />
            </div>
        );
    }
}

export default TrainingInstructions;
